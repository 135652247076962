import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function WorkHistoryComponent(){
    return(
        <Container fluid id="work_history_row">
                <div id="work_history">
                <Row>
                <Col sm={3}></Col>
                </Row> 
                <Row>
                <Col sm={3}></Col>
                    <Col>
                    <Col><h4 style={{paddingBottom:"20px", paddingTop: "200px"}}>Professional Experience</h4></Col>
                        <ul id="work_places">
                        <span className="style2">FANNIE MAE - Remote - May 2022 – Current</span>
                        <div>Full Stack Developer</div>
                        <li>Develop and refractor services with Springboot applications and migrate services to the cloud </li>
                        <li>Monitoring and testing container issues and code vulnerabilities with Fortify scan, Nexus scan, AWS Cloudwatch, and Splunk</li>
                        <li>Manage hosting cloud container-based web applications with AWS</li>
                        <li>Utilized PostgresSQL RDBMS to manage and store data to an AWS database and AWS S3 to store static files</li>
                        <li>Mock JUnit Tests with Powermock and Mockito</li>
                        <li>Leverage and test code on a DevOps pipeline with Bitbucket, utilizing Jenkins to build and create jobs, and deploying with UrbanCode Deploy to AWS Cloud</li>
                        <p/>
                        <span className="style2">USIS - UNITED STATES INFORMATION SYSTEMS - New York, NY -	            September 2021 – November 2021</span>
                        <div>Associate Engineer</div>  	
                        <li>IP and setting up of NVRs and Cameras with ExaqVision</li>
                        <li>Programming Access Readers and Netboxes for Lendel S2 Systems</li>
                        <li>Developing automation scripts for Security Systems with Nodejs and Java Spring</li> 
                        <li>Creating and answering tickets for troubleshooting, and offering support to clients</li>
                        <li>Commissioning and documenting jobs</li>
                        <p/>
                        <span className="style2">BEST BUY - GEEK SQUAD - Levittown, NY -	            May 2021 – November 2021</span>
                        <div>Consultant Agent</div>  	
                        <li>Troubleshooting and Servicing Repairs and Recording Client Statements and Data into Nova</li> 
                        <li>Addressing and Facilitating Clients with their questions, and Educating Clients on their Devices</li> 
                        <li>Assessing and Documenting Open Box Units, Damage Claims, and Processing Client Devices</li>
                        <li>Sending and Receiving Packages and Checking Inventory of Client Units in Store</li>
                        <li>Checking in Clients, Keeping Track of Client Repairs. Updating Clients on their Device Status</li>
                        <li>Offering Services of Best Buy’s and Geek Squad’s Tech Support and Protect Plans to Drive Sales</li>
                        <p/>
                        <span className="style2">NEW YORK INSTITUTE OF TECHNOLOGY - Old Westbury, NY -	            December 2017 – May 2018</span>
                        <div>Independent Developer </div> 	
                        <li>Experience with Java to build Java-based Applications.</li>
                        <li>Worked on building front-end Web Applications with HTML for markup and CSS for styling and interface design.</li> 
                        <li>Leveraged the Bootstrap Framework to build more responsive Web Applications.</li>
                        <li>Utilized Oracle PL/SQL to manage and store data for my databases.</li>
                        <li>Deployed Maven projects with Jenkins and Gitlab on AWS Cloud, as a part of the DevOps pipeline.</li> 
                        <li>Worked with Amazon RDS for database setup and Amazon EC2 for hosting cloud-based web applications.</li> 
                        <li>Implemented Hibernate as an ORM to create Code-First Object-Based Database Applications.</li> 
                        <li>Leveraged Spring MVC and Spring ORM to utilize dependency injection.</li>
                        <p/>
                        <span className="style2">NEW YORK INSTITUTE OF TECHNOLOGY and VISCARDI -	                                 September 2016 – May 2017</span>
                        <div>Team Leader and Developer</div>
                        <li>Created a prototype for a Website Creator designed to be Educational and Accessible for those with disabilities; demonstrated with UML and ERD diagrams.</li>
                        <li>Discussed and collaborated with the people at NYIT/Viscardi about the creation of the project, their vision, organized, and lead the team. Researched, planned, and conceptualized the project.</li>
                        <li>Designed the front-end of the project that would fulfill the requests of Viscardi with HTML5 and CSS.</li>
                        <li>Added user functionality to the website interface with Javascript.</li>
                        <li>Developed the website in Visual Studio, C#, and ASP Webforms.</li>
                        <li>Utilized T-SQL to manage the SQL database.</li>
                        </ul>
                    </Col>
                    <Col sm={3}></Col>
                </Row>
                </div>
                <Col sm={3}></Col>
                <Col sm={3}></Col>
            
        </Container>
    )
}

export default WorkHistoryComponent