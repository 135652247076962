import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Body from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Body />,
  document.getElementById('BodyComponent'),
);



reportWebVitals();