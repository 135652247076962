import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ProjectsComponent(){
    return(
        <div id="projects">
            <Container fluid>
            <Row>
                <Col sm={2}></Col>
                <Col>
                <h4 style={{paddingBottom:"20px", paddingRight:"100px"}}>Projects</h4>
                <dl>
                    <dt>SeleniumScripts</dt>
                    <dd>Springboot hibernate with AWS RDs backend, gitlab CI/CD to containerize image onto AWS EC2, Frontend react mui bootstrap extension using SeleniumAPI</dd>
                </dl>
                <dl>
                    <dt>Employee Management System</dt>
                    <dd>Designed a Spring Boot Web Application to manage Employees</dd>
                </dl>
                <dl>
                    <dt>HumansVSGoblins</dt>
                    <dd>Created a Java Swing GUI, turn based game with Humans and Goblins in a grid and a inventory system</dd>
                </dl>
                <dl>
                    <dt>BoardingPass</dt>
                    <dd>Generated an Ticket based on user input of a Java Swing GUI and Google API</dd>
                </dl>
                <dl>
                    <dt>NumbersToWordsConverted</dt>
                    <dd>Converted a list of random named numbers into numbers and ordered them</dd>
                </dl>
                <dl>
                    <dt>DiscordJobsBot</dt>
                    <dd>A bot that grabs jobs off on Indeed based on user's inputed desired job and location with Python and DiscordAPI</dd>
                </dl>
                </Col>
                <Col sm={2}></Col>
            </Row>
            </Container>
        </div>
    )
}

export default ProjectsComponent