import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ContactLinksComponent(){
    return(
            <Container style={{paddingBottom:"100px", paddingTop:"100px"}}>
                <Row className="justify-content-md-center">
                <Col sm={1}></Col>
                <Col sm={3} className="img-fluid"><div id="linkedin_img"><a href="https://www.linkedin.com/in/janine-lee-/"><img src="/linkedin.png" style={{width:"120px", paddingTop:"20px"}} alt="linkedin_img"/></a></div></Col>
                <Col sm={1}></Col>
                <Col sm={3} className="img-fluid"><div id="gitlab_img"><a href="https://gitlab.com/Tekling"><img src="/gitlab.svg" style={{width:"120px", paddingTop:"20px"}} alt="gitlab_img" /></a></div></Col>
                </Row>
            </Container>
    )
}
export default ContactLinksComponent