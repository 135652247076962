import React from "react";
import Container from "react-bootstrap/esm/Container";
import EmailComponent from "./EmailComponent";

function FooterComponent(){
    return(
            <Container style={{paddingBottom:"100px"}}>
                <footer className="footer">
                    <h4>Contact Me:</h4>
                    <ul id="contact-info">
                    <li style={{paddingLeft: '8px'}}>Janine Lee</li>
                    <li><EmailComponent email={"janinemllee@gmail.com"}/></li>
                    </ul>
                </footer>
            </Container>
    )
}

export default FooterComponent