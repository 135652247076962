import React from 'react';
import './App.css';
import BodyComponent from './components/BodyComponent';

function App() {
  return (
    <div>
    <div id="BodyComponent">
      <BodyComponent /> 
    </div>
  </div>
  );
}

export default App


