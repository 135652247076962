import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function SkillsComponent(){
    return(
        <Container fluid id="profile-skill-row">
            <Row>
            <Col sm={3}><div id="profile_img"><img src="/IMG_20210820_154728.jpg" class="img-fluid" alt="face_img"/></div></Col>
                <div id="tech-skills">
                <Row>
                <Col sm={1}></Col>
                </Row>
                    <ul class="skills" style={{paddingRight:"40px"}}>
                    <Col><h4 style={{paddingBottom:"20px", paddingRight:"20px"}}>Technical Skills</h4></Col>
                        <li><span className="style2">Data technologies:</span> SQL, XML, JSON, AJAX, REST/SOAP Web Services</li>
                        <li><span className="style2">Server Side:</span> Java, Hibernate, Spring Core, Spring MVC, Spring AOP, Spring Boot, Spring Framework </li>
                        <li><span className="style2">Front End:</span> HTML 5, CSS, Bootstrap, Javascript, React</li>
                        <li><span className="style2">Dev Ops:</span> STS, Maven, Jenkins, Git, Github/Gitlab, Amazon RDS, Amazon EC2, GCP, Tomcat, Heroku</li>
                        <li><span className="style2">Other:</span> Microsoft Teams, Visual Studio, ASP, Adobe Photoshop, PC, Mac, iOS, Android, Microsoft Excel</li>
                    </ul>
                </div>
                <Col sm={3}></Col>
                <Col sm={3}></Col>
                <Col sm={3}></Col>
            </Row>
            <Row>
            <Col sm={2}></Col>
            <Col id="skills-used" sm={8}><div id="skills_img"><img src="/skills.svg" className="img-fluid" alt="skills_img"/></div></Col>
            </Row>
        </Container>
    )
}

export default SkillsComponent