import React, {useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import emailjs from 'emailjs-com';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  color: 'black',
  textTransform: 'none'
};

function EmailComponent(getText) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button /*onClick={handleOpen}*/ style={{textTransform: 'none', color: 'white'}}>{getText.email}</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Send me an Email
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <BasicTextFieldComponent/>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

// function sendEmail(e) {
//   e.preventDefault();
//   emailjs.sendForm(
//     'gmail', 
//     process.env.EMAILJS_TEMPLATE_ID, 
//     e.target,  
//     process.env.EMAILJS_API_KEY)
//     .then((result) => {
//         console.log(result.text);
//     }, (error) => {
//         console.log(error.text);
//     });
// };

function BasicTextFieldComponent() {
  const [fromName, setName] = useState('');
  const [fromEmail, setEmail] = useState('');
  const [fromMessage, setMessage] = useState('');
  const HandleName = (e) => {
    setName(e.target.value);
  };
  const HandleEmail = (e) => {
    setEmail(e.target.value);
  };
  const HandleMessage = (e) => {
    setMessage(e.target.value);
  };

  const templateParams={
    from_name:fromName,
    from_email:fromEmail,
    from_message:fromMessage,
  };

  return (
    <form id="contactForm"
    // onSubmit={sendEmail}
    >
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '30ch' },
      }}
      noValidate
      autoComplete="off"
    >
        {/* <TextField id="outlined-basic" label="To" value="janinemllee@gmail.com" disabled="true" style={{width:"320px"}} size="small"/> */}
        <TextField id="outlined-basic" type="email" label="Your Email" size="small" style={{width:"320px"}} name="from_name" value={fromName} onChange={HandleName}/>
        <TextField id="outlined-basic" type="text" label="Your Name" size="small" style={{width:"320px"}} name="from_email" value={fromEmail} onChange={HandleEmail}/>
        <TextField
            id="outlined-textarea"
            label="Message"
            placeholder="Your Text Here"
            multiline
            minRows={10}
            style={{width:"320px"}}
            default='Your Text Here'
            name="from_message"
            value={fromMessage}
            onChange={HandleMessage}
          />
          <input className="btn btn-primary" type="submit" value="Send" style={{width:"320px"}}/>
    </Box>
    </form>
  );
}


export default EmailComponent